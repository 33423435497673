import jwtDefaultConfig from './jwtDefaultConfig'
import jwtMaintainConfig from './jwtMaintainConfig'
import jwtMemberConfig from './jwtMemberConfig'
import jwtRptBillConfig from './jwtRptBillConfig'
import jwtSettingConfig from './jwtSettingConfig'
import jwtTraderConfig from './jwtTraderConfig'
import jwtMessageConfig from './jwtMessageConfig'
import jwtLiveScoutConfig from './jwtLiveScoutConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = {
    ...jwtDefaultConfig, ...jwtMaintainConfig, ...jwtMemberConfig, ...jwtRptBillConfig, ...jwtSettingConfig, ...jwtTraderConfig, ...jwtMessageConfig, ...jwtLiveScoutConfig,
  }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { response } = error
        if (response && response.status === 401) {
          localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName)
          localStorage.removeItem(jwtDefaultConfig.storageRefreshTokenKeyName)
          localStorage.removeItem('userData')
          window.location.href = '../../login'
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  ballusc(...args) {
    return this.axiosIns.post(this.jwtConfig.balluscEndpoint, ...args)
  }

  balluscSetting(...args) {
    return this.axiosIns.post(this.jwtConfig.balluscSettingEndpoint, ...args)
  }

  balluscSettingUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.balluscSettingUpdateEndpoint, ...args)
  }

  balluscEvt(...args) {
    return this.axiosIns.post(this.jwtConfig.balluscEvtEndpoint, ...args)
  }

  balluscEvtAdj(...args) {
    return this.axiosIns.post(this.jwtConfig.balluscEvtAdjEndpoint, ...args)
  }
  
  ballReal(...args) {
    return this.axiosIns.post(this.jwtConfig.ballRealEndpoint, ...args)
  }

  ballQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.ballQueryEndpoint, ...args)
  }

  subTicket(...args) {
    return this.axiosIns.post(this.jwtConfig.subTicketQueryEndpoint, ...args)
  }
    
  scoreQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.scoreQueryEndpoint, ...args)
  }
  
  logQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.logQueryEndpoint, ...args)
  }

  LogDetail(...args) {
    return this.axiosIns.post(this.jwtConfig.logDetailEndpoint, ...args)
  }

  bbsQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.bbsQueryEndpoint, ...args)
  }

  bbsInfo(...args) {
    return this.axiosIns.post(this.jwtConfig.bbsInfoEndpoint, ...args)
  }

  bbsTranslate(...args) {
    return this.axiosIns.post(this.jwtConfig.bbsTranslateEndpoint, ...args)
  }

  bbsInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.bbsInsertEndpoint, ...args)
  }

  bbsUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.bbsUpdateEndpoint, ...args)
  }

  bbsDisplay(...args) {
    return this.axiosIns.post(this.jwtConfig.bbsDisplayEndpoint, ...args)
  }

  bbsDelete(...args) {
    return this.axiosIns.post(this.jwtConfig.bbsDeleteEndpoint, ...args)
  }

  Logout(...args) {
    return this.axiosIns.post(this.jwtConfig.loginoutEndpoint, ...args)
  }

  checkLoginAxios(...args) {
    return this.axiosIns.post(this.jwtConfig.checkLogin2Endpoint, ...args)
  }

  checkLogin() {
    const xmlhttp = new XMLHttpRequest()
    
     xmlhttp.open('GET', this.jwtConfig.checkLoginEndpoint, false)
     xmlhttp.send(null)
     return JSON.parse(xmlhttp.responseText)
  }

  // 賽程管理
  maintainQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.maintainQueryEndpoint, ...args)
  }

  maintainCOQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.maintainCOQueryEndpoint, ...args)
  }

  memCmb(...args) {
    return this.axiosIns.post(this.jwtConfig.memCmbEndpoint, ...args)
  }

  setCrlUser(...args) {
    return this.axiosIns.post(this.jwtConfig.setCrlUserEndpoint, ...args)
  }

  gameScore(...args) {
    return this.axiosIns.post(this.jwtConfig.gameScoreEndpoint, ...args)
  }

  gameDetail(...args) {
    return this.axiosIns.post(this.jwtConfig.gameDetailEndpoint, ...args)
  }

  returnA(...args) {
    return this.axiosIns.post(this.jwtConfig.returnAEndpoint, ...args)
  }

  returnCheck(...args) {
    return this.axiosIns.post(this.jwtConfig.returnCheckEndpoint, ...args)
  }

  returnCheckEvt(...args) {
    return this.axiosIns.post(this.jwtConfig.returnCheckEvtEndpoint, ...args)
  }

  resumeA(...args) {
    return this.axiosIns.post(this.jwtConfig.resumeAEndpoint, ...args)
  }

  returnG(...args) {
    return this.axiosIns.post(this.jwtConfig.returnGEndpoint, ...args)
  }

  live(...args) {
    return this.axiosIns.post(this.jwtConfig.liveEndpoint, ...args)
  }

  savelive(...args) {
    return this.axiosIns.post(this.jwtConfig.saveliveEndpoint, ...args)
  }

  savehotEvt(...args) {
    return this.axiosIns.post(this.jwtConfig.savehotEvtEndpoint, ...args)
  }

  removeNewGame(...args) {
    return this.axiosIns.post(this.jwtConfig.removeNewGameEndpoint, ...args)
  }

  saveGame(...args) {
    return this.axiosIns.post(this.jwtConfig.saveGameEndpoint, ...args)
  }

  deleteGame(...args) {
    return this.axiosIns.post(this.jwtConfig.deleteGameEndpoint, ...args)
  }

  // 組織
  siteQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.siteEndpoint, ...args)
  }

  memberQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.memberEndpoint, ...args)
  }

  userData(...args) {
    return this.axiosIns.post(this.jwtConfig.userDataEndpoint, ...args)
  }

  memberInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.memberInsertEndpoint, ...args)
  }

  memberUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.memberUpdateEndpoint, ...args)
  }

  memberUpdateStatus(...args) {
    return this.axiosIns.post(this.jwtConfig.memberUpdateStatusEndpoint, ...args)
  }
   
  memberUpdatePw(...args) {
    return this.axiosIns.post(this.jwtConfig.memberUpdatePwEndpoint, ...args)
  }

  // 帳務
  meml9Query(...args) {
    return this.axiosIns.post(this.jwtConfig.meml9Endpoint, ...args)
  }

  meml8Query(...args) {
    return this.axiosIns.post(this.jwtConfig.meml8Endpoint, ...args)
  }

  billQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.billEndpoint, ...args)
  }

  billExcelJson(...args) {
    return this.axiosIns.post(this.jwtConfig.billExcelJsonEndpoint, ...args)
  }

  billAllQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.billAllEndpoint, ...args)
  }
  
  billTypeQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.billTypeEndpoint, ...args)
  }
  
  billLeagueQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.billLeagueEndpoint, ...args)
  }

  billGameQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.billGameEndpoint, ...args)
  }

  billCancelQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.billCancelEndpoint, ...args)
  }

  billTicketQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.billTicketEndpoint, ...args)
  }

  // 分析
  analysisQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.analysisQueryEndpoint, ...args)
  }

  memTypeAnalysisQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.memTypeAnalysisQueryEndpoint, ...args)
  }

  winAnalysisQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.winAnalysisQueryEndpoint, ...args)
  }

  memTypeAnalysisQueryChart(...args) {
    return this.axiosIns.post(this.jwtConfig.memTypeAnalysisQueryChartEndpoint, ...args)
  }

  // 參數
  memTransferInfo(...args) {
    return this.axiosIns.post(this.jwtConfig.memTransferEndpoint, ...args)
  }

  memTransferInfoUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.memTransferUpdateEndpoint, ...args)
  }

  cancelticket(...args) {
    return this.axiosIns.post(this.jwtConfig.cancelticketEndpoint, ...args)
  }

  resumeTicket(...args) {
    return this.axiosIns.post(this.jwtConfig.resumeTicketEndpoint, ...args)
  }

  returnTicket(...args) {
    return this.axiosIns.post(this.jwtConfig.returnTicketEndpoint, ...args)
  }

  resetDeposit(...args) {
    return this.axiosIns.post(this.jwtConfig.resetDepositEndpoint, ...args)
  }
  
  acquNoMatchQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.acquNoMatchEndpoint, ...args)
  }

  acquMatchGameSDate(...args) {
    return this.axiosIns.post(this.jwtConfig.acquMatchGameSDateEndpoint, ...args)
  }
  
  acquMatchGame(...args) {
    return this.axiosIns.post(this.jwtConfig.acquMatchGameEndpoint, ...args)
  }

  selectOptions(...args) {
    return this.axiosIns.post(this.jwtConfig.selectOptionsEndpoint, ...args)
  }

  tourQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.tourQueryEndpoint, ...args)
  }

  tourMCQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.tourMCQueryEndpoint, ...args)
  }

  queryByLName(...args) {
    return this.axiosIns.post(this.jwtConfig.queryByLNameEndpoint, ...args)
  }
  
  tourInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.tourInsertEndpoint, ...args)
  }

  tourUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.tourUpdateEndpoint, ...args)
  }

  tourUpdateModID(...args) {
    return this.axiosIns.post(this.jwtConfig.tourUpdateModIDEndpoint, ...args)
  }

  tourDelete(...args) {
    return this.axiosIns.post(this.jwtConfig.tourDeleteEndpoint, ...args)
  }

  betSettingQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.betSettingQueryEndpoint, ...args)
  }

  betSettingInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.betSettingInsertEndpoint, ...args)
  }

  betSettingUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.betSettingUpdateEndpoint, ...args)
  }

  teamQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.teamQueryEndpoint, ...args)
  }

  teamInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.teamInsertEndpoint, ...args)
  }

  teamUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.teamUpdateEndpoint, ...args)
  }

  teamDelete(...args) {
    return this.axiosIns.post(this.jwtConfig.teamDeleteEndpoint, ...args)
  }

  teamPQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.teamPQueryEndpoint, ...args)
  }

  teamPInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.teamPInsertEndpoint, ...args)
  }

  teamPUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.teamPUpdateEndpoint, ...args)
  }

  teamPDelete(...args) {
    return this.axiosIns.post(this.jwtConfig.teamPDeleteEndpoint, ...args)
  }

  oddsConfigQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.oddsConfigQueryEndpoint, ...args)
  }

  oddsConfigUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.oddsConfigUpdateEndpoint, ...args)
  }

  oddsDefaultQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.oddsDefaultQueryEndpoint, ...args)
  }

  oddsDefaultUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.oddsDefaultUpdateEndpoint, ...args)
  }

  siteMaintainQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.siteMaintainQueryEndpoint, ...args)
  }

  sp66M(...args) {
    return this.axiosIns.post(this.jwtConfig.sp66MEndpoint, ...args)
  }

  sp66UM(...args) {
    return this.axiosIns.post(this.jwtConfig.sp66UMEndpoint, ...args)
  }

  getM(...args) {
    return this.axiosIns.post(this.jwtConfig.getMEndpoint, ...args)
  }

  siteMaintainUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.siteMaintainUpdateEndpoint, ...args)
  }

  // 控盤
  traderCommQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.traderCommQueryEndpoint, ...args)
  }

  traderCommQueryHis(...args) {
    return this.axiosIns.post(this.jwtConfig.traderCommQueryHisEndpoint, ...args)
  }

  traderSCPDQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.traderSCPDQueryEndpoint, ...args)
  }

  traderSCPDListQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.traderSCPDListQueryEndpoint, ...args)
  }

  traderCHListQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.traderCHListQueryEndpoint, ...args)
  }

  traderSuper8Query(...args) {
    return this.axiosIns.post(this.jwtConfig.traderSuper8Endpoint, ...args)
  }

  InsertSuper8(...args) {
    return this.axiosIns.post(this.jwtConfig.InsertSuper8Endpoint, ...args)
  }

  traderQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.traderQueryEndpoint, ...args)
  }

  traderOUOEQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.traderOuOeQueryEndpoint, ...args)
  }

  tourLDataQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.tourLDataQueryEndpoint, ...args)
  }

  gameEnd(...args) {
    return this.axiosIns.post(this.jwtConfig.gameEndEndpoint, ...args)
  }

  followSite(...args) {
    return this.axiosIns.post(this.jwtConfig.followSiteEndpoint, ...args)
  }

  saveHdp(...args) {
    return this.axiosIns.post(this.jwtConfig.saveHdpEndpoint, ...args)
  }

  saveOdds(...args) {
    return this.axiosIns.post(this.jwtConfig.saveOddsEndpoint, ...args)
  }

  gameLight(...args) {
    return this.axiosIns.post(this.jwtConfig.gameLightEndpoint, ...args)
  }

  evtOpen(...args) {
    return this.axiosIns.post(this.jwtConfig.evtOpenEndpoint, ...args)
  }

  evtClose(...args) {
    return this.axiosIns.post(this.jwtConfig.evtCloseEndpoint, ...args)
  }

  evtStart(...args) {
    return this.axiosIns.post(this.jwtConfig.evtStartEndpoint, ...args)
  }

  evtStop(...args) {
    return this.axiosIns.post(this.jwtConfig.evtStopEndpoint, ...args)
  }

  hdpUp(...args) {
    return this.axiosIns.post(this.jwtConfig.hdpUpEndpoint, ...args)
  }

  hdpDown(...args) {
    return this.axiosIns.post(this.jwtConfig.hdpDownEndpoint, ...args)
  }

  hdpIsClosed(...args) {
    return this.axiosIns.post(this.jwtConfig.hdpIsClosedEndpoint, ...args)
  }

  hdpIsStopped(...args) {
    return this.axiosIns.post(this.jwtConfig.hdpIsStoppedEndpoint, ...args)
  }

  hdpReset(...args) {
    return this.axiosIns.post(this.jwtConfig.hdpResetEndpoint, ...args)
  }

  hdpPos(...args) {
    return this.axiosIns.post(this.jwtConfig.hdpPosEndpoint, ...args)
  }

  draggable(...args) {
    return this.axiosIns.post(this.jwtConfig.draggableEndpoint, ...args)
  }

  oddsAdj(...args) {
    return this.axiosIns.post(this.jwtConfig.oddsAdjEndpoint, ...args)
  }

  zeroh(...args) {
    return this.axiosIns.post(this.jwtConfig.zerohEndpoint, ...args)
  }
  
  allClose(...args) {
    return this.axiosIns.post(this.jwtConfig.allCloseEndpoint, ...args)
  }

  orgHdp(...args) {
    return this.axiosIns.post(this.jwtConfig.orgHdpEndpoint, ...args)
  }

  dangerHdp(...args) {
    return this.axiosIns.post(this.jwtConfig.dangerHdpEndpoint, ...args)
  }

  superStatus(...args) {
    return this.axiosIns.post(this.jwtConfig.superStatusEndpoint, ...args)
  }

  MCLevelQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.MCLevelQueryEndpoint, ...args)
  }

  MCQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.MCQueryEndpoint, ...args)
  }

  MCSave(...args) {
    return this.axiosIns.post(this.jwtConfig.MCSaveEndpoint, ...args)
  }

  traderStopQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.traderStopQueryEndpoint, ...args)
  }

  gameLogHdpQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.gameLogHdpQueryEndpoint, ...args)
  }

  gameWinLogQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.gameWinLogEndpoint, ...args)
  }
  
  forecastQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.forecastEndpoint, ...args)
  }

  forecastBSQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.forecastBSEndpoint, ...args)
  }
  
  forecastLiveQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.forecastLiveEndpoint, ...args)
  }

  LoadDambes(...args) {
    return this.axiosIns.post(this.jwtConfig.LoadDambesEndpoint, ...args)
  }

  TicketInfo(...args) {
    return this.axiosIns.post(this.jwtConfig.TicketInfoEndpoint, ...args)
  }

  TicketInfoPage(...args) {
    return this.axiosIns.post(this.jwtConfig.TicketInfoPageEndpoint, ...args)
  }

  TicketWaitAll(...args) {
    return this.axiosIns.post(this.jwtConfig.TicketWaitAllEndpoint, ...args)
  }

  TicketWaitAllHis(...args) {
    return this.axiosIns.post(this.jwtConfig.TicketWaitAllHisEndpoint, ...args)
  }

  TicketPassCancelHis(...args) {
    return this.axiosIns.post(this.jwtConfig.TicketPassCancelHisEndpoint, ...args)
  }

  TicketWaitAllPass(...args) {
    return this.axiosIns.post(this.jwtConfig.TicketWaitAllPassEndpoint, ...args)
  }

  TicketWaitAllCancel(...args) {
    return this.axiosIns.post(this.jwtConfig.TicketWaitAllCancelEndpoint, ...args)
  }

  TicketWaitAllReMark(...args) {
    return this.axiosIns.post(this.jwtConfig.TicketWaitAllReMarkEndpoint, ...args)
  }

  TicketWaitReMarkDangerType(...args) {
    return this.axiosIns.post(this.jwtConfig.TicketWaitReMarkDangerTypeEndpoint, ...args)
  }

  sell(...args) {
    return this.axiosIns.post(this.jwtConfig.SellEndpoint, ...args)
  }

  // 辣客
  dangerTypeQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.dangerTypeQueryEndpoint, ...args)
  }

  dangerTypeInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.dangerTypeInsertEndpoint, ...args)
  }

  dangerTypeUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.dangerTypeUpdateEndpoint, ...args)
  }

  memTypeQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.memTypeQueryEndpoint, ...args)
  }

  memTypeInsert(...args) {
    return this.axiosIns.post(this.jwtConfig.memTypeInsertEndpoint, ...args)
  }

  memTypeUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.memTypeUpdateEndpoint, ...args)
  }

  memQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.memQueryEndpoint, ...args)
  }
  
  memSingle(...args) {
    return this.axiosIns.post(this.jwtConfig.memSingleEndpoint, ...args)
  }

  memUpdate(...args) {
    return this.axiosIns.post(this.jwtConfig.memUpdateEndpoint, ...args)
  }

  // 我的最愛
  myFavorite(...args) {
    return this.axiosIns.post(this.jwtConfig.favouriteEndpoint, ...args)
  }

  addFavorite(...args) {
    return this.axiosIns.post(this.jwtConfig.addFavouriteEndpoint, ...args)
  }

  // 客服
  user1Query(...args) {
    return this.axiosIns.post(this.jwtConfig.user1QueryEndpoint, ...args)
  }

  reUser1(...args) {
    return this.axiosIns.post(this.jwtConfig.reMsguser1Endpoint, ...args)
  }

  user2Query(...args) {
    return this.axiosIns.post(this.jwtConfig.user2QueryEndpoint, ...args)
  }

  reUser2(...args) {
    return this.axiosIns.post(this.jwtConfig.reMsguser2Endpoint, ...args)
  }

  // 燈號
  allMatchQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.allMatchQueryEndpoint, ...args)
  }

  matchInfoQuery(...args) {
    return this.axiosIns.post(this.jwtConfig.matchInfoQueryEndpoint, ...args)
  }
  
  matchRB(...args) {
    return this.axiosIns.post(this.jwtConfig.matchRBEndpoint, ...args)
  }

  disconnectRB(...args) {
    return this.axiosIns.post(this.jwtConfig.disconnectEndpoint, ...args)
  }

  uLight(...args) {
    return this.axiosIns.post(this.jwtConfig.uLightEndpoint, ...args)
  }

  greenLight(...args) {
    return this.axiosIns.post(this.jwtConfig.greenLightEndpoint, ...args)
  }

  checkScore(...args) {
    return this.axiosIns.post(this.jwtConfig.checkScoreEndpoint, ...args)
  }

  reverseScore(...args) {
    return this.axiosIns.post(this.jwtConfig.reverseScoreEndpoint, ...args)
  }
}
