// const urlRoot = 'http://localhost:50598'
const urlRoot = '/api'
export default {
  // Endpoints
  
  analysisQueryEndpoint: `${urlRoot}/setting/analysisQuery`,
  memTypeAnalysisQueryEndpoint: `${urlRoot}/setting/memTypeAnalysisQuery`,
  memTypeAnalysisQueryChartEndpoint: `${urlRoot}/setting/memTypeAnalysisQueryChart`,
  winAnalysisQueryEndpoint: `${urlRoot}/setting/winAnalysisQuery`,
  memTransferEndpoint: `${urlRoot}/setting/MemTransferInfo`,
  memTransferUpdateEndpoint: `${urlRoot}/setting/MemTransferInfoUpdate`,
  cancelticketEndpoint: `${urlRoot}/setting/cancelticket`,
  resumeTicketEndpoint: `${urlRoot}/setting/resumeTicket`,
  returnTicketEndpoint: `${urlRoot}/setting/returnTicket`,
  resetDepositEndpoint: `${urlRoot}/setting/resetDeposit`,
  
  acquNoMatchEndpoint: `${urlRoot}/setting/acquNoMatch`,
  acquMatchGameSDateEndpoint: `${urlRoot}/setting/acquMatchGameSDate`,
  acquMatchGameEndpoint: `${urlRoot}/setting/acquMatchGame`,

  selectOptionsEndpoint: `${urlRoot}/setting/selectOptions`,
  tourQueryEndpoint: `${urlRoot}/setting/tourQuery`,
  tourMCQueryEndpoint: `${urlRoot}/setting/tourMCQuery`,
  queryByLNameEndpoint: `${urlRoot}/setting/queryByLName`,
  tourInsertEndpoint: `${urlRoot}/setting/tourInsert`,
  tourUpdateEndpoint: `${urlRoot}/setting/tourUpdate`,
  tourUpdateModIDEndpoint: `${urlRoot}/setting/tourUpdateModID`,
  tourDeleteEndpoint: `${urlRoot}/setting/tourDelete`,

  betSettingQueryEndpoint: `${urlRoot}/setting/betSettingQuery`,
  betSettingInsertEndpoint: `${urlRoot}/setting/betSettingInsert`,
  betSettingUpdateEndpoint: `${urlRoot}/setting/betSettingUpdate`,
  betSettingDeleteEndpoint: `${urlRoot}/setting/betSettingDelete`,

  teamQueryEndpoint: `${urlRoot}/setting/teamQuery`,
  teamInsertEndpoint: `${urlRoot}/setting/teamInsert`,
  teamUpdateEndpoint: `${urlRoot}/setting/teamUpdate`,
  teamDeleteEndpoint: `${urlRoot}/setting/teamDelete`,

  teamPQueryEndpoint: `${urlRoot}/setting/teamPQuery`,
  teamPInsertEndpoint: `${urlRoot}/setting/teamPInsert`,
  teamPUpdateEndpoint: `${urlRoot}/setting/teamPUpdate`,
  teamPDeleteEndpoint: `${urlRoot}/setting/teamPDelete`,

  oddsConfigQueryEndpoint: `${urlRoot}/setting/oddsConfigQuery`,
  oddsConfigUpdateEndpoint: `${urlRoot}/setting/oddsConfigUpdate`,

  oddsDefaultQueryEndpoint: `${urlRoot}/setting/oddsDefaultQuery`,
  oddsDefaultUpdateEndpoint: `${urlRoot}/setting/oddsDefaultUpdate`,

  siteMaintainQueryEndpoint: `${urlRoot}/setting/siteMaintainQuery`,
  siteMaintainUpdateEndpoint: `${urlRoot}/setting/siteMaintainUpdate`,
  
  sp66MEndpoint: `${urlRoot}/setting/sp66M`,
  sp66UMEndpoint: `${urlRoot}/setting/sp66UM`,
  getMEndpoint: `${urlRoot}/setting/getM`,

}
