export default [
  {
    path: '/bill',
    name: 'bill',
    component: () => import('@/views/pages/rptbill/bill.vue'),
    meta: {
      title: '報表',
    },
  },
  {
    path: '/billAll',
    name: 'billAll',
    component: () => import('@/views/pages/rptbill/billAll.vue'),
    meta: {
      layout: 'full',
      width: 1380,
      height: 650,
      title: '總帳',
    },
  },
  {
    path: '/billLeague',
    name: 'billLeague',
    component: () => import('@/views/pages/rptbill/billLeague.vue'),
    meta: {
      layout: 'full',
      width: 1380,
      height: 650,
      title: '聯賽報表',
    },
  },
  {
    path: '/billType',
    name: 'billType',
    component: () => import('@/views/pages/rptbill/billType.vue'),
    meta: {
      layout: 'full',
      width: 1380,
      height: 650,
      title: '分類帳',
    },
  },
  {
    path: '/billGame',
    name: 'billGame',
    component: () => import('@/views/pages/rptbill/billGame.vue'),
    meta: {
      layout: 'full',
      width: 1380,
      height: 650,
      title: '賽事帳',
    },
  },
  {
    path: '/billCancel',
    name: 'billCancel',
    component: () => import('@/views/pages/rptbill/billCancel.vue'),
    meta: {
      layout: 'full',
      width: 1380,
      height: 650,
      title: '注單',
    },
  },
  {
    path: '/billTicket',
    name: 'billTicket',
    component: () => import('@/views/pages/rptbill/billTicket.vue'),
    meta: {
      layout: 'full',
      title: '注單',
    },
  },
  {
    path: '/billRTicket',
    name: 'billRTicket',
    component: () => import('@/views/pages/rptbill/billRTicket.vue'),
    meta: {
      layout: 'full',
      title: '注單',
    },
  },
  {
    path: '/excel',
    name: 'excel',
    component: () => import('@/views/pages/rptbill/excel.vue'),
    meta: {
      layout: 'full',
      type: 'open',
      width: 1380,
      height: 650,
      title: 'excel',
    },
  },
]
