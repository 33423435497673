import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed, // 垂直菜單是否折疊
    $title: '', // 標題
    $otherInfo: {}, // 其他信息
    $responseTime: 0, // 響應時間
    $message: {
      mt1: 0, mt2: 0, mt1p: 0, mt2p: 0, // 不同類型的消息計數
    },
    $messageUser: '', // 消息用戶
  },
  getters: {}, // 目前沒有定義任何 getter
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      // 更新垂直菜單的折疊狀態
      localStorage.setItem('isCollapsed', val ? '1' : '0') // 將狀態保存到 localStorage
      state.isVerticalMenuCollapsed = val // 更新 state
    },
    updateTitle: (state, val) => {
      // 更新標題和其他信息
      state.$title = val.title
      state.$otherInfo = val
    },
    updateTime: (state, val) => {
      // 更新響應時間
      state.$responseTime = val
    },
    updateMessage: (state, val) => {
      // 更新消息計數
      state.$message = val
    },
    updateMessageUser: (state, val) => {
      // 更新消息用戶
      state.$messageUser = val
    },
  },
  actions: {}, // 目前沒有定義任何 action
}
