// 引入 Vue 核心庫
import Vue from 'vue'

// 引入 Bootstrap Vue 的插件
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'

// 引入 Vue Composition API,這是 Vue 3 的一些特性在 Vue 2 中的實現
import VueCompositionAPI from '@vue/composition-api'

// 引入國際化配置
import i18n from '@/libs/i18n'

// 引入路由配置
import router from './router'

// 引入 Vuex store
import store from './store'

// 引入根組件
import App from './App.vue'

// 引入全局組件
import './global-components'

// 引入第三方插件
import '@axios' // 可能是 Axios 的自定義配置
import '@/libs/acl' // 訪問控制列表配置
import '@/libs/portal-vue' // Portal Vue 插件
import '@/libs/clipboard' // 剪貼板功能
import '@/libs/toastification' // Toast 通知功能
import '@/libs/sweet-alerts' // SweetAlert 彈窗功能
import '@/libs/vue-select' // Vue Select 組件
import '@/libs/tour' // 可能是新手引導功能

// Axios Mock Adapter (目前被註釋掉,可能用於模擬 API 響應)
// import '@/@fake-db/db'

// 註冊 Bootstrap Vue 插件
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// 使用 Vue Composition API
Vue.use(VueCompositionAPI)

// 設置全局屬性 $appName
Vue.prototype.$appName = 'My App'

// 引入 Feather 圖標字體 (用於表單嚮導)
// 如果不使用 Feather 圖標,可以移除這行
require('@core/assets/fonts/feather/iconfont.css')

// 引入核心樣式
require('@core/scss/core.scss')

// 引入資源樣式
require('@/assets/scss/style.scss')

// 設置生產提示為 false
Vue.config.productionTip = false

// 創建 Vue 實例
new Vue({
  router, // 注入路由
  store, // 注入 Vuex store
  i18n, // 注入國際化
  render: h => h(App), // 渲染根組件
}).$mount('#app') // 掛載到 id 為 'app' 的 DOM 元素上
