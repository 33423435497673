// const urlRoot = 'http://localhost:50598'
const urlRoot = '/api'
export default {
  // Endpoints
  traderCommQueryEndpoint: `${urlRoot}/Trader/queryComm`,
  traderCommQueryHisEndpoint: `${urlRoot}/Trader/queryCommHis`,
  traderSCPDQueryEndpoint: `${urlRoot}/Trader/querySCPD`,
  traderSCPDListQueryEndpoint: `${urlRoot}/Trader/querySCPDList`,
  traderCHListQueryEndpoint: `${urlRoot}/Trader/queryCHList`,
  traderSuper8Endpoint: `${urlRoot}/Trader/querySuper8`,
  InsertSuper8Endpoint: `${urlRoot}/Trader/InsertSuper8`,
  traderQueryEndpoint: `${urlRoot}/BallEn/query`,
  traderOuOeQueryEndpoint: `${urlRoot}/Trader/ouoe`,

  tourLDataQueryEndpoint: `${urlRoot}/BallEn/tourLDataQuery`,

  traderStopQueryEndpoint: `${urlRoot}/BallStop/query`,

  gameLogHdpQueryEndpoint: `${urlRoot}/BallEn/gameLogHdp`,
  gameWinLogEndpoint: `${urlRoot}/Trader/gameWinLog`,
  forecastEndpoint: `${urlRoot}/Trader/forecast`,
  forecastBSEndpoint: `${urlRoot}/Trader/forecastBS`,
  forecastLiveEndpoint: `${urlRoot}/Trader/forecastLive`,

  followSiteEndpoint: `${urlRoot}/BallEn/followSite`,
  saveHdpEndpoint: `${urlRoot}/BallEn/saveHdp`,
  saveOddsEndpoint: `${urlRoot}/BallEn/saveOdds`,

  gameLightEndpoint: `${urlRoot}/BallEn/gameLight`,
  gameEndEndpoint: `${urlRoot}/BallEn/gameEnd`,
  evtOpenEndpoint: `${urlRoot}/BallEn/evtOpen`,
  evtCloseEndpoint: `${urlRoot}/BallEn/evtClose`,
  evtStartEndpoint: `${urlRoot}/BallEn/evtStart`,
  evtStopEndpoint: `${urlRoot}/BallEn/evtStop`,

  hdpDownEndpoint: `${urlRoot}/BallEn/hdpDown`,
  hdpUpEndpoint: `${urlRoot}/BallEn/hdpUp`,
  hdpIsClosedEndpoint: `${urlRoot}/BallEn/hdpIsClosed`,
  hdpIsStoppedEndpoint: `${urlRoot}/BallEn/hdpIsStopped`,
  hdpResetEndpoint: `${urlRoot}/BallEn/hdpReset`,
  hdpPosEndpoint: `${urlRoot}/BallEn/hdpPos`,
  draggableEndpoint: `${urlRoot}/BallEn/draggable`,
  oddsAdjEndpoint: `${urlRoot}/BallEn/oddsAdj`,
  allCloseEndpoint: `${urlRoot}/BallEn/allClose`,
  orgHdpEndpoint: `${urlRoot}/BallEn/orgHdp`,
  dangerHdpEndpoint: `${urlRoot}/BallEn/dangerHdp`,
  superStatusEndpoint: `${urlRoot}/BallEn/superStatus`,
  zerohEndpoint: `${urlRoot}/BallEn/zreoh`,

  MCLevelQueryEndpoint: `${urlRoot}/BallEn/MCLevelQuery`,
  MCQueryEndpoint: `${urlRoot}/BallEn/MCQuery`,
  MCSaveEndpoint: `${urlRoot}/BallEn/MCSave`,
  LoadDambesEndpoint: `${urlRoot}/BallEn/loadDambes`,
  TicketInfoEndpoint: `${urlRoot}/BallEn/TicketInfo`,
  TicketInfoPageEndpoint: `${urlRoot}/BallEn/TicketInfoPage`,
  TicketWaitAllEndpoint: `${urlRoot}/TicketWaitAll/Query`,
  TicketWaitAllHisEndpoint: `${urlRoot}/TicketWaitAll/HisQuery`,
  TicketPassCancelHisEndpoint: `${urlRoot}/TicketWaitAll/PassCancelQuery`,
  TicketWaitAllPassEndpoint: `${urlRoot}/TicketWaitAll/PassTicket`,
  TicketWaitAllCancelEndpoint: `${urlRoot}/TicketWaitAll/CancelTicket`,
  TicketWaitAllReMarkEndpoint: `${urlRoot}/TicketWaitAll/ReMarkTicket`,
  TicketWaitReMarkDangerTypeEndpoint: `${urlRoot}/TicketWaitAll/ReMarkDangerType`,
  SellEndpoint: `${urlRoot}/BallEn/Sell`,
}
