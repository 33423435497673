// const urlRoot = 'http://localhost:50598'
const urlRoot = '/api'
export default {
  // Endpoints
  meml9Endpoint: `${urlRoot}/rptbill/meml9`,
  meml8Endpoint: `${urlRoot}/rptbill/meml8`,
  billEndpoint: `${urlRoot}/rptbill/bill`,
  billExcelJsonEndpoint: `${urlRoot}/rptbill/billExcelJson`,
  billAllEndpoint: `${urlRoot}/rptbill/billAll`,
  billTypeEndpoint: `${urlRoot}/rptbill/billType`,
  billLeagueEndpoint: `${urlRoot}/rptbill/billLeague`,
  billGameEndpoint: `${urlRoot}/rptbill/billGame`,
  billCancelEndpoint: `${urlRoot}/rptbill/billCancel`,
  billTicketEndpoint: `${urlRoot}/rptbill/billTicket`,
}
