export default [
  {
    path: '/maintain',
    name: 'maintain',
    component: () => import('@/views/pages/maintain/index.vue'),
    meta: {
      title: '賽程',
    },
  },
  {
    path: '/hsc',
    name: 'hsc',
    component: () => import('@/views/pages/maintain/hsc.vue'),
    meta: {
      title: '歷史賽事',
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/pages/maintain/checkout.vue'),
    meta: {
      layout: 'full',
      title: '未回帳',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/newGame',
    name: 'newGame',
    component: () => import('@/views/pages/maintain/newGame.vue'),
    meta: {
      layout: 'full',
      title: '新賽事',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
]
