import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    layout: {
      isRTL: $themeConfig.layout.isRTL, // 是否為從右到左的佈局
      skin: localStorage.getItem('vuexy-skin') || $themeConfig.layout.skin, // 皮膚主題
      routerTransition: $themeConfig.layout.routerTransition, // 路由轉場動畫
      type: $themeConfig.layout.type, // 佈局類型
      contentWidth: $themeConfig.layout.contentWidth, // 內容寬度
      menu: {
        hidden: $themeConfig.layout.menu.hidden, // 菜單是否隱藏
      },
      navbar: {
        type: $themeConfig.layout.navbar.type, // 導航欄類型
        backgroundColor: $themeConfig.layout.navbar.backgroundColor, // 導航欄背景色
      },
      footer: {
        type: $themeConfig.layout.footer.type, // 頁腳類型
      },
    },
  },
  getters: {},
  mutations: {
    TOGGLE_RTL(state) {
      // 切換從右到左的佈局
      state.layout.isRTL = !state.layout.isRTL
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
    },
    UPDATE_SKIN(state, skin) {
      // 更新皮膚主題
      state.layout.skin = skin

      // 更新 localStorage 中的值
      localStorage.setItem('vuexy-skin', skin)

      // 更新 DOM 以應用暗色佈局
      if (skin === 'dark') document.body.classList.add('dark-layout')
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      // 更新路由轉場動畫
      state.layout.routerTransition = val
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      // 更新佈局類型
      state.layout.type = val
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      // 更新內容寬度
      state.layout.contentWidth = val
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      // 更新菜單隱藏狀態
      state.layout.menu.hidden = val
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      // 更新導航欄配置
      Object.assign(state.layout.navbar, obj)
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      // 更新頁腳配置
      Object.assign(state.layout.footer, obj)
    },
  },
  actions: {},
}
