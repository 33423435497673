import Vue from 'vue'
import Vuex from 'vuex'

// 引入各個模塊
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// 在 Vue 中使用 Vuex
Vue.use(Vuex)

// 創建並導出 Vuex store 實例
export default new Vuex.Store({
  // 註冊模塊
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  // 嚴格模式: 在開發環境中啟用,生產環境中禁用
  strict: process.env.DEV,
})
