import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true, // 啟用命名空間
  state: {
    windowWidth: 0, // 存儲窗口寬度
    shallShowOverlay: false, // 控制是否顯示遮罩層
  },
  getters: {
    // 根據當前窗口寬度返回對應的斷點
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    // 更新窗口寬度
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    // 切換遮罩層顯示狀態
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {}, // 目前沒有定義任何 action
}
