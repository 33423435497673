<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]">
    <!-- 動態組件: 根據當前路由動態渲染不同的佈局組件 -->
    <component :is="layout">
      <!-- router-view: 路由視圖,用於顯示當前路由匹配的組件 -->
      <router-view />
    </component>

    <!-- 回到頂部組件: 僅在 enableScrollToTop 為 true 時顯示 -->
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
// 引入回到頂部組件
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// 引入主題相關配置,這些將在 beforeCreate 鉤子中被初始化
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
// 引入 toast 通知提供器
import { provideToast } from 'vue-toastification/composition'
// 引入 Vue Composition API 的 watch 函數
import { watch } from '@vue/composition-api'
// 引入應用配置 hook
import useAppConfig from '@core/app-config/useAppConfig'

// 引入 VueUse 的工具函數
import { useWindowSize, useCssVar } from '@vueuse/core'

// 引入 Vuex store
import store from '@/store'

// 懶加載佈局組件,提高首次加載速度
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // 註冊佈局組件和回到頂部組件
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ScrollToTop,
  },
  // 計算屬性
  computed: {
    // 根據路由元信息和內容佈局類型決定使用哪種佈局
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    // 從 Vuex store 獲取內容佈局類型
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  // 在實例創建之前
  beforeCreate() {
    // 設置主題顏色
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      // 使用 useCssVar 獲取 CSS 變量值並設置到 $themeColors 對象中
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // 設置主題斷點
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      // 獲取斷點值並轉換為數字
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // 設置 RTL (從右到左閱讀方向)
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  // 組合式 API 設置函數
  setup() {
    // 使用應用配置 hook
    const { skin, skinClasses } = useAppConfig()
    // 從主題配置中獲取是否啟用回到頂部功能
    const { enableScrollToTop } = $themeConfig.layout

    // 如果初始皮膚是深色,則給 body 添加深色佈局類
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // 為使用 Composition API 的組件提供 toast 功能
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // 在 store 中設置初始窗口寬度
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    // 使用 VueUse 的 useWindowSize 來監聽窗口大小變化
    const { width: windowWidth } = useWindowSize()
    // 當窗口寬度變化時,更新 store 中的值
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    // 返回模板中需要使用的屬性
    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
