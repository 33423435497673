// const urlRoot = 'http://localhost:50598'
const urlRoot = '/api'
export default {
  // Endpoints
  allMatchQueryEndpoint: `${urlRoot}/LiveScout/AllMatchQuery`,
  matchInfoQueryEndpoint: `${urlRoot}/LiveScout/matchInfoQuery`,
  matchRBEndpoint: `${urlRoot}/LiveScout/matchRB`,
  disconnectEndpoint: `${urlRoot}/LiveScout/disconnectRB`,
  uLightEndpoint: `${urlRoot}/LiveScout/uLight`,
  greenLightEndpoint: `${urlRoot}/LiveScout/greenLight`,
  checkScoreEndpoint: `${urlRoot}/LiveScout/checkScore`,
  reverseScoreEndpoint: `${urlRoot}/LiveScout/reverseScore`,
}
